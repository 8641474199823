import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './FilterForm.css';
import CustomSlider from './RangeSlider.js';

const FilterForm = ({ onFilterChange, onSearchChange, availableSizes, availableBrands, availableCategory, onReset }) => {
    const [filters, setFilters] = useState({
        brand: [],
        sizes: [],
        priceRange: [0, 25000],
        category: [],
        sort: 'price_desc',
        gender: []
    });
    const [openDropdown, setOpenDropdown] = useState(null);
    const [sliderValue, setSliderValue] = useState(filters.priceRange);

    useEffect(() => {
        const { priceRange: [minPrice, maxPrice], ...restFilters } = filters;
        onFilterChange({ ...restFilters, minPrice, maxPrice });
    }, [filters]);

    const handleReset = () => {
        const resetFilters = {
            brand: [],
            sizes: [],
            priceRange: [0, 25000],
            category: [],
            sort: 'price_desc',  // Reset to default sort on reset
            gender: []
        };

        setFilters(resetFilters);
        setSliderValue([0, 25000]);
        onFilterChange(resetFilters);
        onSearchChange({ target: { value: '' } });
    };

    const toggleDropdown = (label) => {
        setOpenDropdown(prev => (prev === label ? null : label));
    };

    const handleSortToggle = () => {
        setFilters(prev => ({
            ...prev,
            sort: prev.sort === 'price_desc' ? 'price_asc' : 'price_desc'
        }));
    };

    const renderSortIcon = () => {
        const icon = filters.sort === 'price_asc' ? 'sortupBlack.svg' :
            filters.sort === 'price_desc' ? 'sorddownBlack.svg' : 'sortBlack.svg';
        return <img src={`/assets/${icon}`} alt="Sort" className="filter-icon" />;
    };

    const getSortText = () => (
        filters.sort === 'price_asc' ? 'Цена растечка' : filters.sort === 'price_desc' ? 'Цена опаѓачка' : 'Цена'
    );

    const handleFilterChange = (setter, value) => {
        setFilters(prev => ({
            ...prev,
            [setter]: prev[setter].includes(value)
                ? prev[setter].filter(item => item !== value)
                : [...prev[setter], value]
        }));
    };

    const renderDropdown = (items, stateKey, label, displayItems, iconSrc, customClass = '') => (
        <div className={`dropdown mb-3 ${customClass}`}>
            <button
                className="btn btn-primary dropdown-toggle d-flex justify-content-between align-items-center w-100"
                type="button"
                aria-expanded={openDropdown === label}
                onClick={() => toggleDropdown(label)}
            >
                <span className="d-flex align-items-center">
                    <img src={iconSrc} alt={`${label} icon`} className="filter-icon" />
                    <span className="ms-2">{label}</span>
                </span>
            </button>
            <ul className={`dropdown-menu${openDropdown === label ? ' show' : ''}`} aria-labelledby={`${label}-dropdown`}>
                <div className="dropdown-item-container">
                    {items.map((item, index) => (
                        <li key={item} className="dropdown-item">
                            <div className="dropdown-item-custom" onClick={() => handleFilterChange(stateKey, item)}>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={item}
                                        checked={filters[stateKey].includes(item)}
                                        onChange={() => { }}
                                    />
                                    <label className="form-check-label">{displayItems ? displayItems[index] : item}</label>
                                </div>
                            </div>
                        </li>
                    ))}
                </div>
            </ul>
        </div>
    );

    return (
        <form onSubmit={(e) => e.preventDefault()} id="filterForm" className="py-0 py-md-3 mt-0">

            {renderDropdown(availableCategory, 'category', 'Категорија', null, '/assets/categoryBlack.svg')}
            {renderDropdown(availableBrands, 'brand', 'Бренд', null, '/assets/brandBlack.svg')}
            {renderDropdown(['male', 'female'], 'gender', 'Пол', ['Машки', 'Женски'], '/assets/genderBlack.svg')}
            {renderDropdown(availableSizes, 'sizes', 'Големини', null, '/assets/sizeBlack.svg', 'sizes-dropdown')}

            <div className="dropdown mb-3">
                <button className="btn btn-primary d-flex justify-content-between align-items-center w-100" type="button" onClick={handleSortToggle}>
                    <span className="d-flex align-items-center">
                        {renderSortIcon()}
                        <span className="ms-2">{getSortText()}</span>
                    </span>
                </button>
            </div>

            <div className="mb-3">
                <CustomSlider
                    values={sliderValue}
                    onChange={setSliderValue}
                    onAfterChange={(values) => setFilters(prev => ({ ...prev, priceRange: values }))}
                />
            </div>

            <button type="button" className="btn btn-filter w-100" onClick={handleReset}>
                Ресетирај филтри
            </button>
        </form>
    );
};

export default FilterForm;
