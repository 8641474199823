import React, { useState } from 'react';
import './ShoeCard.css';

const ShoeCard = ({ shoe }) => {
    if (!shoe || !shoe.shoes) return null;

    const shoeDetails = shoe.shoes;
    const storeCount = shoe.stores.length;

    // Define font sizes based on store count
    const titleSize = '1.6rem';
    const storeNameSize = '1.5rem';
    const priceSize = '1.3rem';
    const brandSize = '1.25rem';
    const categorySize = '1.25rem';
    const imageSize = '190px';

    const getImageByTitle = (url) => {
        let formattedUrl = url.replace(/_/g, '/', 5);
        formattedUrl = formattedUrl.replace(/-/g, ':');
        return `/images/${formattedUrl}.jpg`;
    };

    // Determine if stores should be scrollable
    const isScrollable = storeCount >= 3;

    // Extract store prices and find the lowest price
    const storePrices = shoe.stores.map(store => parseFloat(store.price)).filter(Boolean);
    const lowestPrice = storePrices.length > 0 ? Math.min(...storePrices) : null;

    // Function to get color based on store name
    const getStoreColor = (storeName) => {
        switch (storeName) {
            case 'SPORT VISION': return '#EDD83D';
            case 'SPORT REALITY': return '#92D5E6';
            case 'Д-СПОРТ': return '#F75C03';
            case 'СПОРТ-М': return '#81066D';
            case 'INTERSPORT': return '#17BEBB';
            case 'BUZZ SNEAKERS': return '#EE66A6';
            case 'MEGASPORT': return '#3ea908';
            default: return '#000';
        }
    };

    // Get unique stores by name
    const uniqueStores = Array.from(new Map(shoe.stores.map(store => [store.store, store])).values());

    // Get the color of the first store
    const firstStoreColor = getStoreColor(uniqueStores[0]?.store);

    return (
        <div className="card shadow-0 rounded-1 mb-3" style={{ borderColor: firstStoreColor }}>
            <div className="card-body">
                <div className="row">
                    {/* Left Side: Title, Image, Categories */}
                    <div className="col-md-12 col-lg-7 d-flex flex-column border-right" style={{ borderColor: firstStoreColor }}>
                        {/* Title */}
                        <h5 style={{ fontSize: titleSize, marginBottom: '1rem' }}>
                            {shoeDetails.title || 'Shoe Title'}
                        </h5>

                        <div className="d-flex card-info">
                            {/* Image */}
                            <div className="shoe-image-container">
                                <div className="bg-image hover-zoom ripple rounded ripple-surface" style={{ height: imageSize }}>
                                    <img
                                        referrerPolicy="no-referrer"
                                        src={getImageByTitle(shoeDetails.url)}
                                        alt={shoeDetails.title || 'Shoe Image'}
                                        className="w-100"
                                        style={{ objectFit: 'cover', height: imageSize, borderRadius: '12px' }}
                                        onError={(e) => { e.target.src = shoeDetails.image }} // Fallback to color-specific image on error
                                    />
                                </div>
                            </div>

                            {/* Categories and Details */}
                            <div className="d-flex flex-column details">
                                <p style={{ fontSize: brandSize, marginBottom: '0.5rem' }}>
                                    <strong>Бренд:</strong> {shoeDetails.brand || 'Brand Name'}
                                </p>
                                <p style={{ fontSize: categorySize, marginBottom: '0.5rem' }}>
                                    <strong>Категорија:</strong> {shoeDetails.category || 'Category Name'}
                                </p>
                                <p style={{ fontSize: categorySize, marginBottom: '0.5rem' }}>
                                    <strong>Пол:</strong> {shoeDetails.gender === 'male' ? 'Машки' : 'Женски'}
                                </p>
                            </div>
                        </div>
                    </div>

                    {storeCount === 1 ? (
                        <a
                            href={uniqueStores[0].url}
                            target='_blank'
                            rel='noopener noreferrer'
                            className={`col-md-12 col-lg-5 d-flex text-decoration-none flex-column ${isScrollable ? 'scrollable-stores' : ''}`}
                        >
                            {uniqueStores.map((store, index) => {
                                const storePrice = parseFloat(store.price);
                                const isLowestPrice = storePrice === lowestPrice;

                                // Get store color
                                const storeColor = getStoreColor(store.store);

                                // Apply color to title and hover animation
                                const storeTitleStyle = {
                                    fontSize: storeNameSize,
                                    textDecoration: 'underline',
                                };

                                const priceStyle = {
                                    fontSize: priceSize,
                                    color: storeCount === 1 || isLowestPrice ? '#3BB238' : 'red',
                                };

                                return (
                                    <React.Fragment key={index}>
                                        {index > 0 && (<div className="border-horizontal my-0" style={{ borderColor: firstStoreColor }} />)}
                                        <StoreContainer
                                            storeColor={storeColor}
                                            store={store}
                                            storeTitleStyle={storeTitleStyle}
                                            priceStyle={priceStyle}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </a>
                    ) : (
                        <div className={`col-md-12 col-lg-5 d-flex flex-column ${isScrollable ? 'scrollable-stores' : ''}`}>
                            {uniqueStores.map((store, index) => {
                                const storePrice = parseFloat(store.price);
                                const isLowestPrice = storePrice === lowestPrice;

                                // Get store color
                                const storeColor = getStoreColor(store.store);

                                // Apply color to title and hover animation
                                const storeTitleStyle = {
                                    fontSize: storeNameSize,
                                    textDecoration: 'underline',
                                };

                                const priceStyle = {
                                    fontSize: priceSize,
                                    color: storeCount === 1 || isLowestPrice ? '#3BB238' : 'red',
                                };

                                return (
                                    <React.Fragment key={index}>
                                        {index > 0 && (<div className="border-horizontal my-0" style={{ borderColor: firstStoreColor }} />)}
                                        <StoreContainer
                                            storeColor={storeColor}
                                            store={store}
                                            storeTitleStyle={storeTitleStyle}
                                            priceStyle={priceStyle}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const StoreContainer = ({ storeColor, store, storeTitleStyle, priceStyle }) => {
    const [hovered, setHovered] = useState(false);

    // Set CSS variables for store color
    const containerStyle = {
        '--store-color': storeColor,
    };

    return (
        <div
            className={`store-container ${hovered ? 'hovered' : 'default-background'}`}
            style={containerStyle}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <a href={store.url} target='_blank' rel='noopener noreferrer' className="text-decoration-none py-2 text-black store-link d-flex flex-column justify-content-end align-items-start">
                <div className="mx-4">
                    <h5 className="mb-6 text-center" style={storeTitleStyle}>
                        {store.store || 'Store Name'}
                    </h5>

                    {/* Sizes in a mini-grid layout */}
                    {Array.isArray(store.sizes) && store.sizes.length > 0 && (
                        <div className="size-grid mb-2 ">
                            {store.sizes.map((size, index) => (
                                <div key={index} className="size-box ">{size}</div>
                            ))}
                        </div>
                    )}

                    <div className="price-container mt-2 ">
                        <h5 className="text-end price" style={priceStyle}>
                            {store.price || 'Price'} <span className="denari">денари</span>
                        </h5>
                    </div>
                </div>
            </a>
        </div>
    );
};

export default ShoeCard;
