import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css'; // Import custom CSS for additional styling

function Header() {
    return (
        <nav className="navbar w-100 navbar-expand-lg navbar-dark bg-teal">
            <div className="d-flex px-0 mt-md-3 mt-0 justify-content-between align-items-center" style={{ height: '120px' }}>
                <a className="navbar-brand arrow d-none d-md-block" href="/">
                    <img
                        loading='lazy'
                        src='/assets/arrow1.png' // Path to your left-side image
                        alt='Left Image'
                    />
                </a>

                <a className="navbar-brand logo mx-auto" href="/">
                    <img
                        loading='urgent'
                        src='/assets/logof.svg'
                        rel="preload"
                        alt='Logo'
                    />
                </a>

                <a className="navbar-brand eyes d-none d-lg-block" href="/">
                    <img
                        loading='lazy'
                        src='/assets/eyes2.png' // Path to your right-side image
                        alt='Eyes Image'
                    />
                </a>
            </div>
        </nav>
    );
}

export default Header;
