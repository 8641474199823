import Header from './components/Partials/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from './pages/HomePage';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import './styles/style.css';
import './styles/ShoeCard.css';


function App() {

  return (
    <div className="d-flex flex-column min-vh-100">

      <Header />
      <div>

        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />

            {/* Other routes */}
          </Routes>
        </Router>

      </div>



    </div>
  );
}

export default App;
