import React, { useEffect, useState, useRef, useCallback } from 'react';
import ShoeCard from '../components/Shoe/ShoeCard';
import FilterForm from '../components/Filters/FilterForm';
import debounce from 'lodash.debounce';

const HomePage = () => {
    const [state, setState] = useState({
        allShoeClusters: [],
        shoeClusters: [],
        visibleCount: 15,
        totalItems: 0,
        filters: {},
        searchTerm: '',
        availableSizes: [],
        filteredSizes: [],
        availableBrands: [],
        availableCategory: [],
        hasMoreResults: true,
    });

    const filtersRef = useRef(state.filters);
    const observerRef = useRef(null); // For Intersection Observer

    useEffect(() => {
        filtersRef.current = state.filters;
    }, [state.filters]);

   
    const convertSizeToNumber = (size) => {
        const [integerPart, fractionPart] = size.split(' ');
        let number = parseFloat(integerPart);
        if (fractionPart) {
            const [numerator, denominator] = fractionPart.split('/').map(Number);
            number += numerator / denominator;
        }
        return number;
    };

    const sortSizes = (sizes) => {
        return sizes
            .map(size => ({ size, number: convertSizeToNumber(size) }))
            .sort((a, b) => a.number - b.number)
            .map(item => item.size);
    };

    const renderShoeCardWithAds = (shoeClusters) => {
        return shoeClusters.map((shoe, index) => (
            <React.Fragment key={index}>
                <div className="row-md-1 px-3 mb-4">
                    <ShoeCard shoe={shoe} />
                </div>

            </React.Fragment>
        ));
    };



    const fetchFilterOptions = async () => {
        try {
            const response = await fetch('https://backend.vidipatiki.mk/api/patiki/options');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setState(prevState => ({
                ...prevState,
                availableSizes: sortSizes([...new Set(data.sizes)]),
                availableBrands: data.brands,
                availableCategory: data.categories,
            }));
        } catch (err) {
            console.error('Error fetching filter options:', err);
        }
    };

    const fetchShoeClusters = async (append = false) => {
        const { visibleCount, searchTerm } = state;
        try {
            const query = new URLSearchParams();
            const page = Math.ceil(visibleCount / 15);

            Object.entries(filtersRef.current).forEach(([key, value]) => {
                if (Array.isArray(value) && value.length > 0) {
                    value.forEach(val => query.append(key, val));
                } else if (value) {
                    query.append(key, value);
                }
            });

            if (searchTerm) {
                query.append('title', searchTerm);
            }

            query.append('page', 1);
            query.append('limit', 500);

            const response = await fetch(`https://backend.vidipatiki.mk/api/patiki?${query.toString()}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const { results, totalCount } = data;

            // Store all results in state
            setState(prevState => ({
                ...prevState,
                allShoeClusters: results,
                totalItems: totalCount,
                shoeClusters: results.slice(0, prevState.visibleCount), // Load initially visible items
            }));
        } catch (err) {
            console.error('Error fetching shoe clusters:', err);
        }
    };

    const loadVisibleItems = useCallback((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const index = Number(entry.target.getAttribute('data-index'));
                if (!state.shoeClusters[index]) {
                    // Fetch more items if not already loaded
                    const newShoeClusters = state.allShoeClusters.slice(state.shoeClusters.length, state.shoeClusters.length + 5);
                    setState(prevState => ({
                        ...prevState,
                        shoeClusters: [
                            ...prevState.shoeClusters,
                            ...newShoeClusters,
                        ],
                    }));
                }
                observerRef.current.unobserve(entry.target);
            }
        });
    }, [state.allShoeClusters, state.shoeClusters]);

    useEffect(() => {
        const observer = new IntersectionObserver(loadVisibleItems);
        observerRef.current = observer;

        const shoeCards = document.querySelectorAll('.shoe-card');
        shoeCards.forEach((card, index) => {
            card.setAttribute('data-index', index);
            observer.observe(card);
        });

        return () => {
            shoeCards.forEach(card => observer.unobserve(card));
        };
    }, [loadVisibleItems]);

    const calculateLimit = () => {
        const remainingItems = state.totalItems - state.visibleCount;
        return Math.min(15, Math.max(10, remainingItems));
    };

    const handleLoadMore = useCallback(debounce(() => {
        if (state.hasMoreResults) {
            const limit = calculateLimit();
            setState(prevState => ({
                ...prevState,
                visibleCount: prevState.visibleCount + limit,
                shoeClusters: [
                    ...prevState.shoeClusters,
                    ...prevState.allShoeClusters.slice(prevState.shoeClusters.length, prevState.shoeClusters.length + limit)
                ],
            }));
        }
    }, 200), [state.hasMoreResults, state.allShoeClusters]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchFilterOptions();
                await fetchShoeClusters();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (Object.keys(state.filters).length > 0 || state.searchTerm) {
            setState(prevState => ({
                ...prevState,
                visibleCount: 15,
                hasMoreResults: true,
            }));
            fetchShoeClusters();
        }
    }, [state.filters, state.searchTerm]);

    const handleScroll = () => {
        const scrollTop = window.scrollY;
        const scrollHeight = document.documentElement.scrollHeight;
        const windowHeight = window.innerHeight;

        if (scrollTop + windowHeight >= scrollHeight - 1.5 * windowHeight && state.hasMoreResults) {
            handleLoadMore();
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleLoadMore, state.hasMoreResults]);

    useEffect(() => {
        if (state.filteredSizes.length === 0) {
            setState(prevState => ({
                ...prevState,
                shoeClusters: prevState.allShoeClusters.slice(0, prevState.visibleCount),
            }));
        } else {
            const filteredClusters = state.allShoeClusters.filter(shoe =>
                shoe.stores.some(store => store.sizes.some(size => state.filteredSizes.includes(size)))
            );
            setState(prevState => ({
                ...prevState,
                shoeClusters: filteredClusters.slice(0, prevState.visibleCount),
            }));
        }
    }, [state.filteredSizes, state.visibleCount, state.allShoeClusters]);

    const handleFiltersChange = (newFilters) => {
        setState(prevState => ({
            ...prevState,
            filters: newFilters,
            filteredSizes: newFilters.sizes || [],
            visibleCount: 15,
            hasMoreResults: true,
        }));

        const scrollTop = window.scrollY;
        const windowHeight = window.innerHeight;

        if (scrollTop > 1.5 * windowHeight) {
            scrollToTop();
        }
    };

    const handleReset = () => {
        setState({
            allShoeClusters: [],
            shoeClusters: [],
            visibleCount: 15,
            totalItems: 0,
            filters: {},
            searchTerm: '',
            availableSizes: [],
            filteredSizes: [],
            availableBrands: [],
            availableCategory: [],
            hasMoreResults: true,
        });
        scrollToTop();
    };

    const handleSearchChange = (event) => {
        const { value } = event.target;
        setState(prevState => ({
            ...prevState,
            searchTerm: value,
            visibleCount: 15,
            hasMoreResults: true,
        }));
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="container-fluid my-4 mt-md-4 mt-0">
            <div className="row">
                <div className="col-md-2 px-2">
                    <FilterForm
                        onFilterChange={handleFiltersChange}
                        availableSizes={state.availableSizes}
                        availableBrands={state.availableBrands}
                        availableCategory={state.availableCategory}
                        onReset={handleReset}
                        searchTerm={state.searchTerm}
                        onSearchChange={handleSearchChange}

                    />
                </div>
                <div className="col-md-8 mr-auto">
                    <div className="row-md-1 px-3 mb-4">
                        <label>Побарај по име:</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Пример:AIR JORDAN"
                            value={state.searchTerm} 
                            onChange={handleSearchChange}
                        />
                    </div>
                    <div className="col">
                        {state.shoeClusters.length > 0 ? (
                            renderShoeCardWithAds(state.shoeClusters) 
                        ) : (
                            <div className="text-center">
                                <p>No shoes available.</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="text-center mb-4">
                        {/* Commented out AdSense ad */}
                        {/* <ins className="adsbygoogle"
                            style={{ display: 'block' }}
                            data-ad-client="ca-pub-8359265918516228"
                            data-ad-slot="4578785787"
                            data-ad-format="auto"
                            data-full-width-responsive="true">
                        </ins> */}
                    </div>
                </div>
            </div>
            <button
                onClick={scrollToTop}
                className="btn btn-primary rounded-circle position-fixed bottom-0 end-0 m-3"
                style={{ width: '50px', height: '50px', fontSize: '24px' }}
            >
                ↑
            </button>
        </div>
    );
};

export default HomePage;
