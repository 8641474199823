import Slider from 'react-slider';
import './RangeSlider.css';

const MIN = 0;
const MAX = 25000;
const STEP = 200;

const CustomSlider = ({ values, onChange, min = MIN, max = MAX, onAfterChange }) => {
    return (
        <div className="custom-range-slider">
            <div className='box'>

                <div className={'values my-2 d-flex justify-content-center align-items-center'}>
                    {values[0]}ден.- {values[1]}ден.
                </div>

                <Slider
                    className={'slider'}
                    value={values}
                    min={min}
                    max={max}
                    step={STEP} // Add step prop here
                    onChange={onChange}  // Update slider value as it changes
                    onAfterChange={onAfterChange} // Apply filter only after the user stops interacting
                />
            </div>
        </div>
    );
};

export default CustomSlider;
